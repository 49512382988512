const ROWS = {
  UNDER_18: "<18",
  BETWEEN_18_24: "18 - 24",
  BETWEEN_25_34: "25 - 34",
  BETWEEN_35_44: "35 - 44",
  BETWEEN_45_54: "45 - 54",
  AFTER_55: "55+",
};

export default ROWS;
