import AGE_ROWS from "pages/Networks/NewNetworkViewInsight/cards/age/ageRows";
import GENDER_ROWS from "pages/Networks/NewNetworkViewInsight/cards/gender/genderRows";
import INCOME_ROWS from "pages/Networks/NewNetworkViewInsight/cards/income/incomeRows";
import PARENTAL_ROWS from "pages/Networks/NewNetworkViewInsight/cards/parentalStatus/parentalRows";
import POWER_SCORE_ROWS from "pages/Networks/NewNetworkViewInsight/cards/powerscore/powerScoreRows";

import addCommasToNumber from "utils/misc/addCommasToNumber";

export const getPowerScoreData = (powerScoreResponse, powerScoreMedian) => {
  const median = powerScoreMedian?.find((bucket) => bucket.key === "active")
    ?.median?.["50.0"];
  const medianForNonActive = powerScoreMedian?.find(
    (bucket) => bucket.key === "idle"
  )?.median?.["50.0"];

  const values = {
    [POWER_SCORE_ROWS.ZERO_TEN]:
      powerScoreResponse?.find((bucket) => bucket.key === "0_9")?.doc_count ||
      0,
    [POWER_SCORE_ROWS.TEN_TWENTY]:
      powerScoreResponse?.find((bucket) => bucket.key === "10_19")?.doc_count ||
      0,
    [POWER_SCORE_ROWS.TWENTY_THIRTY]:
      powerScoreResponse?.find((bucket) => bucket.key === "20_29")?.doc_count ||
      0,
    [POWER_SCORE_ROWS.THIRTY_FORTY]:
      powerScoreResponse?.find((bucket) => bucket.key === "30_39")?.doc_count ||
      0,
    [POWER_SCORE_ROWS.FORTY_FIFTY]:
      powerScoreResponse?.find((bucket) => bucket.key === "40_49")?.doc_count ||
      0,
    [POWER_SCORE_ROWS.FIFTY_SIXTY]:
      powerScoreResponse?.find((bucket) => bucket.key === "50_59")?.doc_count ||
      0,
    [POWER_SCORE_ROWS.SIXTY_SEVENTY]:
      powerScoreResponse?.find((bucket) => bucket.key === "60_69")?.doc_count ||
      0,
    [POWER_SCORE_ROWS.SEVENTY_EIGHTY]:
      powerScoreResponse?.find((bucket) => bucket.key === "70_79")?.doc_count ||
      0,
    [POWER_SCORE_ROWS.EIGHTY_NINETY]:
      powerScoreResponse?.find((bucket) => bucket.key === "80_89")?.doc_count ||
      0,
    [POWER_SCORE_ROWS.NINETY_HUNDRED]:
      powerScoreResponse?.find((bucket) => bucket.key === "90_100")
        ?.doc_count || 0,
  };

  const isEmpty = Object.values(values)?.every(
    (elem) => elem === 0 || elem === undefined
  );

  return {
    median: median || medianForNonActive,
    values,
    isEmpty,
  };
};

export const getAgeData = (ageInfoResponse) => {
  const values = {
    [AGE_ROWS.UNDER_18]:
      ageInfoResponse?.find((bucket) => bucket.key === "0_18")?.doc_count || 0,
    [AGE_ROWS.BETWEEN_18_24]:
      ageInfoResponse?.find((bucket) => bucket.key === "18_24")?.doc_count || 0,
    [AGE_ROWS.BETWEEN_25_34]:
      ageInfoResponse?.find((bucket) => bucket.key === "25_34")?.doc_count || 0,
    [AGE_ROWS.BETWEEN_35_44]:
      ageInfoResponse?.find((bucket) => bucket.key === "35_44")?.doc_count || 0,
    [AGE_ROWS.BETWEEN_45_54]:
      ageInfoResponse?.find((bucket) => bucket.key === "45_54")?.doc_count || 0,
    [AGE_ROWS.AFTER_55]:
      ageInfoResponse?.find((bucket) => bucket.key === "55+")?.doc_count || 0,
  };

  const median = Object.keys(values)?.reduce((a, b) =>
    values[a] > values[b] ? a : b
  );

  const isEmpty = Object.values(values)?.every(
    (elem) => elem === 0 || elem === undefined
  );

  return {
    median: median,
    values,
    isEmpty,
  };
};

export const getParentalData = (parentsResponse) => {
  const parents = parentsResponse?.reduce((acc, bucket) => {
    if (bucket.key === "highParent" || bucket.key === "parent") {
      return acc + bucket.doc_count;
    }
    return acc;
  }, 0);
  const nonParents = parentsResponse?.reduce((acc, bucket) => {
    if (bucket.key === "nonParent" || bucket.key === "highNonParent") {
      return acc + bucket.doc_count;
    }
    return acc;
  }, 0);

  const values = {
    [PARENTAL_ROWS.PARENTS]: parents,
    [PARENTAL_ROWS.NOT_PARENTS]: nonParents,
    [PARENTAL_ROWS.MIXED]:
      parentsResponse?.find((bucket) => bucket.key === "mixed")?.doc_count || 0,
  };

  const median = Object.keys(values)?.reduce((a, b) =>
    values[a] > values[b] ? a : b
  );

  const isEmpty = Object.values(values)?.every(
    (elem) => elem === 0 || elem === undefined
  );

  return {
    median,
    values,
    isEmpty,
  };
};

export const getTopInterestsData = (topInterestsResponse) => {
  if (!topInterestsResponse || topInterestsResponse.length === 0) {
    return {
      median: undefined,
      values: {},
      isEmpty: true,
    };
  }

  const transformedValues = {};

  topInterestsResponse?.forEach((bucket, index) => {
    transformedValues[index] = {
      name: bucket?.key,
      value: bucket?.doc_count,
    };
  });

  return {
    median: topInterestsResponse[0]?.key,
    values: transformedValues,
    isEmpty: false,
  };
};

export const getIncomeData = (incomeResponse) => {
  const values = {
    [INCOME_ROWS.$]:
      incomeResponse?.find((bucket) => bucket.key === "$")?.doc_count || 0,
    [INCOME_ROWS.$$]:
      incomeResponse?.find((bucket) => bucket.key === "$$")?.doc_count || 0,
    [INCOME_ROWS.$$$]:
      incomeResponse?.find((bucket) => bucket.key === "$$$")?.doc_count || 0,
    [INCOME_ROWS.$$$$]:
      incomeResponse?.find((bucket) => bucket.key === "$$$$")?.doc_count || 0,
    [INCOME_ROWS.$$$$$]:
      incomeResponse?.find((bucket) => bucket.key === "$$$$$")?.doc_count || 0,
  };

  const median = Object.keys(values)?.reduce((a, b) =>
    values[a] > values[b] ? a : b
  );

  const isEmpty = Object.values(values)?.every(
    (elem) => elem === 0 || elem === undefined
  );

  return {
    median,
    values,
    isEmpty,
  };
};

export const getDemographicsData = (genderResponseBuckets) => {
  const female = genderResponseBuckets?.reduce((acc, bucket) => {
    if (bucket.key === "highFemale" || bucket.key === "moderateFemale") {
      return acc + bucket.doc_count;
    }
    return acc;
  }, 0);

  const male = genderResponseBuckets?.reduce((acc, bucket) => {
    if (bucket.key === "highMale" || bucket.key === "moderateMale") {
      return acc + bucket.doc_count;
    }
    return acc;
  }, 0);

  const mixed =
    genderResponseBuckets?.find((bucket) => bucket.key === "lowMale")
      ?.doc_count +
    genderResponseBuckets?.find((bucket) => bucket.key === "lowFemale")
      ?.doc_count;

  let median = GENDER_ROWS.MIXED;

  if (female > male && female > mixed) {
    median = GENDER_ROWS.FEMALE;
  }

  if (male > female && male > mixed) {
    median = GENDER_ROWS.MALE;
  }

  const values = {
    [GENDER_ROWS.FEMALE]: female,
    [GENDER_ROWS.MALE]: male,
    [GENDER_ROWS.MIXED]: mixed,
  };

  const isEmpty = Object.values(values)?.every(
    (elem) => elem === 0 || elem === undefined
  );

  return {
    median: median,
    values,
    isEmpty,
  };
};

export const getTopCountriesData = (topCountriesResponse) => {
  if (!topCountriesResponse) {
    return {
      median: 0,
      values: [],
      isEmpty: true,
    };
  }

  const topCountry = topCountriesResponse?.buckets[0]?.key;
  const values = topCountriesResponse?.buckets?.slice(0, 5)?.map((elem) => {
    const percentage = elem.doc_count / topCountriesResponse?.doc_count;

    return {
      title: elem?.key,
      count: elem?.doc_count,
      percentage,
    };
  });

  return {
    topCountry,
    values,
    isEmpty: false,
  };
};

export const getMonthlyListenersData = (monthlyListenersResponse) => {
  const formattedResponse = monthlyListenersResponse?.map((item) => {
    const formattedKey = item.key
      .split(" - ")
      .map((num) => addCommasToNumber(num))
      .join(" - ");

    return { ...item, key: formattedKey };
  });

  const isEmpty = formattedResponse?.every((elem) => elem.doc_count === 0);

  return {
    values: formattedResponse,
    isEmpty,
  };
};

export const getStatusData = (statusResponse) => {
  const activeCount = statusResponse?.reduce((acc, bucket) => {
    if (bucket.key === "Active") {
      return acc + bucket.doc_count;
    }
    return acc;
  }, 0);

  const inactiveCount = statusResponse?.reduce((acc, bucket) => {
    if (
      bucket.key === "Maybe-finished" ||
      bucket.key === "Idle" ||
      bucket.key === "Maybe-inactive"
    ) {
      return acc + bucket.doc_count;
    }
    return acc;
  }, 0);

  const totalPodcast = statusResponse?.reduce((acc, bucket) => {
    return acc + bucket.doc_count;
  }, 0);

  const values = {
    Active: activeCount,
    Inactive: inactiveCount,
    Total: totalPodcast,
  };

  const isEmpty = Object.values(values)?.every(
    (elem) => elem === 0 || elem === undefined
  );

  return {
    values,
    isEmpty,
  };
};

export const getCategoryData = (categoryResponse) => {
  if (!categoryResponse || categoryResponse.length === 0) {
    return {
      median: undefined,
      values: [],
      isEmpty: true,
    };
  }

  const values = categoryResponse?.map((bucket) => {
    return {
      name: bucket?.entity?.text,
      value: bucket?.doc_count,
    };
  });

  return {
    median: categoryResponse && categoryResponse[0]?.entity.text,
    values,
    isEmpty: false,
  };
};

export const getReachEstimateData = (
  reachEstimateToData,
  reachEstimateFromData
) => {
  if (!reachEstimateToData || !reachEstimateFromData) {
    return {
      reachEstimateTo: 0,
      reachEstimateFrom: 0,
      isEmpty: true,
    };
  }

  return {
    reachEstimateTo: reachEstimateToData[0]?.value,
    reachEstimateFrom: reachEstimateFromData[0]?.value,
    isEmpty: false,
  };
};
