const ROWS = {
  ZERO_TEN: "0 - 10",
  TEN_TWENTY: "10 - 20",
  TWENTY_THIRTY: "20 - 30",
  THIRTY_FORTY: "30 - 40",
  FORTY_FIFTY: "40 - 50",
  FIFTY_SIXTY: "50 - 60",
  SIXTY_SEVENTY: "60 - 70",
  SEVENTY_EIGHTY: "70 - 80",
  EIGHTY_NINETY: "80 - 90",
  NINETY_HUNDRED: "90 - 100",
};

export default ROWS;
